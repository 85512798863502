
<style scoped lang="less">
.Student_Move {
  background: #fff;
  padding: 20px;
  box-shadow: @shadow;
  .item {
    display: flex;
    align-items: center;
    margin-top: 20px;
    .el-select {
      width: 220px;
      margin: 0 10px;
    }
  }
}
</style>
<template>
  <div class="Student_Move">
    <div class="stu-module-header">
      <div class="stu-module-title">院校电子教材
        <div style="display: inline-block; width: 3vh"></div>
        <button type="primary"  style="display: inline-block;background-color: #307EF3;color: aliceblue;border: none;padding: 10px;border-radius: 5px" @click="queryMyTeachingMaterial()">我的电子教材</button>
      </div>
    </div>
    <div style="margin-top: 30px">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="教材名称、教材编号、ISBN、主编:">
          <el-input v-model="formInline.info" placeholder="请输入查询内容" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div style="margin-top: 10px">
      <el-table :data="tableData" border
                size="medium"
                height="630px"
                max-height="630px"
                :header-cell-style="{'text-align':'center'}"
                :cell-style="{'text-align':'center'}"
                style="width: 100%">
        <el-table-column type="index" width="80" label="序号">
        </el-table-column>
        <el-table-column prop="bookName" sortable label="教材名称">
        </el-table-column>
        <el-table-column prop="bookNo" sortable label="教材编码">
        </el-table-column>
        <el-table-column prop="mainEditor" sortable label="作者">
        </el-table-column>
        <el-table-column prop="isbn" sortable label="isbn">
        </el-table-column>
        <el-table-column prop="className" sortable label="类别名称">
        </el-table-column>
        <el-table-column prop="price" sortable label="单价">
        </el-table-column>
        <el-table-column prop="discount" sortable label="折扣">
        </el-table-column>
        <el-table-column prop="sellPrice" sortable label="售价">
        </el-table-column>
        <el-table-column prop="pressName" sortable label="出版社">
        </el-table-column>
        <el-table-column prop="edition" sortable label="版次">
        </el-table-column>
        <el-table-column prop="remark" sortable label="备注">
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button-group>
              <el-button @click="buy(scope.row)" type="primary" size="mini">
                购买
              </el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
      <!-- 共页数 -->
      <div style="padding: 1rem" class="bottom">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="search.page"
            :page-sizes="[10,15,20]"
            :page-size="search.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>



    <el-dialog class="add" title="我的电子教材" :visible.sync="putDialog" width="60%" center>
      <div style="margin-top: 20px">
        <el-table
            size="medium"
            height="600"
            max-height="600"
            :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}"
            ref="multipleTable"
            :data="tableDataPutDialog"
            border
            tooltip-effect="dark"
            style="width: 100%">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column
              width="80px"
              type="index"
              label="序号">
          </el-table-column>
          <el-table-column
              prop="bookName"
              show-overflow-tooltip
              label="教材名称">
          </el-table-column>
          <el-table-column
              prop="bookNo"
              show-overflow-tooltip
              label="教材编号">
          </el-table-column>
          <el-table-column
              prop="sellPrice"
              show-overflow-tooltip
              label="价格">
          </el-table-column>
          <el-table-column
              prop="orderNo"
              show-overflow-tooltip
              label="订单号">
          </el-table-column>
          <el-table-column
              prop="payStatus"
              show-overflow-tooltip
              label="缴费状态">
            <template slot-scope="scope">
              <span v-if="scope.row.payStatus===1">未确认</span>
              <span v-if="scope.row.payStatus===0">已确认</span>
              <span v-if="scope.row.payStatus===-1">已作废</span>
            </template>
          </el-table-column>
          <el-table-column
              prop="payTime"
              show-overflow-tooltip
              label="支付时间">
          </el-table-column>
        </el-table>
        <el-pagination
            @size-change="handleSizeChange2"
            @current-change="handleCurrentChange2"
            :current-page="searchPutDialog.page"
            :page-sizes="[10, 30, 50, 100]"
            :page-size="searchPutDialog.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalPutDialog">
        </el-pagination>
      </div>
    </el-dialog>


    <el-dialog
        title="订单确认"
        :visible.sync="buyDialog"
        width="40%">
      <el-form label-position="left" style="font-weight: bold">
        <el-form-item label="教材名称：" label-width="140px">
          {{electronicTextbookInformation.bookName}}
        </el-form-item>
        <el-form-item label="教材编号：" label-width="140px">
          {{electronicTextbookInformation.bookNo}}
        </el-form-item>
        <el-form-item label="教材类别名称：" label-width="140px">
          {{electronicTextbookInformation.className}}
        </el-form-item>
        <el-form-item label="售价：" label-width="140px">
          {{electronicTextbookInformation.sellPrice}}
        </el-form-item>
        <el-form-item label="出版社：" label-width="140px">
          {{electronicTextbookInformation.pressName}}
        </el-form-item>
        <el-form-item label="版次：" label-width="140px">
          {{electronicTextbookInformation.edition}}
        </el-form-item>
        <el-form-item label="主编：" label-width="140px">
          {{electronicTextbookInformation.mainEditor}}
        </el-form-item>
        <el-form-item label="ISBN：" label-width="140px">
          {{electronicTextbookInformation.isbn}}
        </el-form-item>
        <el-form-item label="备注：" label-width="140px">
          <el-input v-model="electronicTextbook.remark" placeholder="请输入备注" clearable></el-input>
        </el-form-item>
        <el-form-item label="支付方式：" label-width="140px">
          <el-radio-group v-model="electronicTextbook.payType">
            <el-radio :label="3" border style="width: 120px">
              <img src="@/assets/pay/wechat.png" style="width: 70px;position: absolute;top:5px;left:30px">
            </el-radio>
            <el-radio :label="1" border style="width: 120px">
              <img src="@/assets/pay/alipay.png" style="width: 70px;position: absolute;top:5px;left:30px">
            </el-radio>
          </el-radio-group>
          <div style="clear: both;"></div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="buyDialog = false">取 消</el-button>
        <el-button type="primary" @click="commitPay">确 定</el-button>
      </div>
    </el-dialog>


    <el-dialog
        :visible.sync="wxPayment.payImg"
        width="30%">
      <div style="text-align: center;width: 40%">
        <img :src="wxPayment.payUrl" alt="" style="margin-left: 8.5vw">
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closeAll">我已完成支付</el-button>
      </div>
    </el-dialog>

    <el-dialog
        :visible.sync="zfbPayment.aliPay"
        width="30%">
      <div style="text-align: center">
        <p class="alertFoot">如果您遇到问题，可以拨打客服电话</p>
        <el-button type="primary" style="margin-top: 15px" @click="closeAll">我已完成支付</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      buyDialog: false,
      zfbPayment: {
        aliPay: false,
      },
      wxPayment: {
        payImg: false,
        payUrl: '',
      },
      electronicTextbookInformation: {
        bookName: null, //教材名称
        bookNo: null, //教材编号
        className: null, //教材类别名称
        sellPrice: null,		//售价
        pressName: null, //出版社
        edition: null, //版次
        mainEditor: null,	//主编
        isbn: null, //ISBN
        stock: 0,//库存
        locationNo: 0, //库位号
      },
      electronicTextbook:{
        textBookId: null, //教材id
        remark: null, //备注
        payType: 3, //支付方式
      },
      putDialog: false,
      tableDataPutDialog: [],
      totalPutDialog: 0,
      searchPutDialog: {
        page: 1,
        pageSize: 10,
      },
      formInline: {
        info: null,
      },
      total: 0,
      tableData:[],
      search: {
        page: 1,
        pageSize: 10,
      },
    };
  },
  mounted() {
    this.query();
  },
  methods: {
    query() {
      this.openLoadingView();
      this.$axios_supermallData({
        url: "hnjxjy-core/eduTextbook/list",
        method: "get",
        params: {
          page: this.search.page,
          pageSize: this.search.pageSize,
          info: this.formInline.info
        }
      }).then((res) => {
        if (res.data.code === 2000){
          this.tableData = res.data.data.list;
          this.total = res.data.data.total;
        }
        this.loadingView.close();
      }).catch((err) => {
        this.loadingView.close();
      });
    },
    queryMyTeachingMaterial(){
      this.tableDataPutDialog = [];
      this.putDialog = true;
      setTimeout(() => {
        this.openLoadingView();
        this.$axios_supermallData({
          url: "hnjxjy-core/eduTextbook/queryStuTextBookAll",
          method: "get",
          params: {
            page: this.searchPutDialog.page,
            pageSize: this.searchPutDialog.pageSize,
          }
        }).then((res) => {
          if (res.data.code === 2000){
            this.tableDataPutDialog = res.data.data.list;
            this.totalPutDialog = res.data.data.total;
          }
          this.loadingView.close();
        }).catch((err) => {
          this.loadingView.close();
        });
      }, 100);
    },
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.query();
    },
    handleCurrentChange(val) {
      this.search.page = val;
      this.query();
    },
    handleSizeChange2(val) {
      this.searchPutDialog.pageSize = val;
      this.queryMyTeachingMaterial();
    },
    handleCurrentChange2(val) {
      this.searchPutDialog.page = val;
      this.queryMyTeachingMaterial();
    },
    onSubmit (){
      this.query();
    },
    queryEduOrder(textBookId){
      this.openLoadingView();
      this.$axios_supermallData.get("/hnjxjy-core/eduOrder/queryEduOrderByOrderType",{params:{orderType:3,textBookId:textBookId}}).then(resp =>{
        this.electronicTextbookInformation = resp.data.data;
        this.loadingView.close();
      }).catch(reason => {
        this.loadingView.close();
      })
    },
    buy(row) {
      this.buyDialog = true;
      this.electronicTextbook.textBookId = row.id;
      this.electronicTextbook.remark = null;
      this.electronicTextbookInformation = {
        bookName: null, //教材名称
        bookNo: null, //教材编号
        className: null, //教材类别名称
        sellPrice: null,		//售价
        pressName: null, //出版社
        edition: null, //版次
        mainEditor: null,	//主编
        isbn: null, //ISBN
        stock: 0,//库存
        locationNo: 0, //库位号
      };
      setTimeout(() => {
        this.queryEduOrder(row.id);
      }, 100);
    },
    commitPay() {
      let formData = new FormData();
      formData.append("orderType","3");
      formData.append("textBookId",this.electronicTextbook.textBookId);
      formData.append("payType",this.electronicTextbook.payType);
      formData.append("remark",this.electronicTextbook.remark);
      this.openLoadingView();
      this.$axios_supermallData.post("/hnjxjy-core/eduOrder/insertEduOrderByOrderType",formData).then(resp => {
        if (resp.data.code === 2000){
          let paymentFormData = new FormData();
          this.orderId = resp.data.data;
          paymentFormData.append("id",resp.data.data); //订单id
          if (this.electronicTextbook.payType === 3){ //微信支付
            this.$axios_supermallData.post("/hnjxjy-core/wxService/wxPaymentOrder",paymentFormData).then((wxRes) => {
              if (wxRes.data.code === 2000) {
                this.wxPayment.payImg = true;
                this.$nextTick(() => {
                  let base = 'https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=';
                  this.wxPayment.payUrl = base + wxRes.data.data.code_url;
                });
              }
              this.loadingView.close();
            }).catch((err) => {
              this.loadingView.close();
            });
          }else { //支付宝支付
            this.$axios_supermallData.post("/hnjxjy-core/alipay/goPay",paymentFormData).then((zfbRes) => {
              let dataObj = zfbRes.data;
              let dwSafari;
              dwSafari = window.open();
              dwSafari.document.open();
              let html = dataObj.replace(/[^\u0000-\u00FF]/g, function ($0) {
                return escape($0).replace(/(%u)(\w{4})/gi, "&#x$2;")
              });
              dwSafari.document.write("<html><head><title></title><meta charset='utf-8'><body>" + html + "</body></html>")
              dwSafari.document.forms[0].submit();
              dwSafari.document.close();
              this.zfbPayment.aliPay = true;
              this.loadingView.close();
            }).catch((err) => {
              this.loadingView.close();
            });
          }
        }else {
          this.loadingView.close();
        }
      }).catch((err) => {
        this.loadingView.close();
      });
    },
    closeAll() {
      this.openLoadingView()
      this.$axios_supermallData.get('/hnjxjy-core/alipay/verificationUserPay?id='+this.orderId).then(res=>{
        if(res.data.code===2000){
          if(res.data.data==="0"){
            this.$message.success("购买成功");
            this.loadingView.close();
            this.wxPayment.payImg = false;
            this.buyDialog=false;
            this.init()
          }else if(res.data.data==="1"){
            this.$message.error("未支付此订单");
            this.loadingView.close();
          }else if(res.data.data==="-1"){
            this.$message.error("购买失败,请重试");
            this.loadingView.close();
          }
        }else if(res.data.code===5002){
          this.$message.warning(res.data.message);
          this.loadingView.close();
        }else{
          this.$message.error(res.data.message);
          this.loadingView.close();
        }
      }).catch((err) => {
        this.loadingView.close();
      });
    },
  }
};
</script>
